import React from 'react'

import Menu from '../pages/Index'
import Feedback from '../pages/feedback/Index'

const menuRoutes = [
	{ path: "/menu/:pos_id", component: <Menu /> },
]

const ratingRoutes = [
	{ path: "/feedback/:pos_id/:cheque_order_type_id?/:transaction_id?", component: <Feedback /> },
]

export { menuRoutes, ratingRoutes }