import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { menuRoutes, ratingRoutes } from './routes'

function createMenuRoutes() {
	return menuRoutes.map((item, key) => {
		return (
			<Route key={key} path={item.path} element={item.component} />
		);
	});
}

function createRatingRoutes() {
	return ratingRoutes.map((item, key) => {
		return (
			<Route key={key} path={item.path} element={item.component} />
		);
	});
}

const Index = () => {
	return (
		<Routes>
			<Route>
				{createMenuRoutes()}
			</Route>

			<Route>
				{createRatingRoutes()}
			</Route>
		</Routes>
	)
}

export default Index;