import React, { useEffect } from "react";
import { useSelector } from 'react-redux'
import { BrowserRouter as Router } from "react-router-dom";
import Index from "./routes/index"
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css'
import './assets/css/bootstrap.css'
import './assets/css/bootstrap-override.css'
import './assets/css/iconscout.css'
import './assets/css/menu.css'


function App() {
	const loader = useSelector(state => state.loader)
	const menuData = useSelector(state => state.menuData)

	useEffect(() => {
		document.body.className = localStorage.getItem('theme')

		// localStorage.clear('state')
	}, [menuData]);

	return (
		<>
			<Router>
				<Index />
			</Router>

			<ToastContainer
				position="bottom-left"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				theme="colored"
			/>

			{loader &&
				<div id="loading-bg">
					<div className="loading">
						<div className="effect-1 effects"></div>
						<div className="effect-2 effects"></div>
						<div className="effect-3 effects"></div>
					</div>
				</div>
			}
		</>
	);
}

export default App;
